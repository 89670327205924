<div *ngIf="exactMatchConflictPatient; else potentialDuplicate" class="col m-4">
	<div *ngIf="step === 1">
		<div>
			<div class="warning-title mb-3">
				<fa-icon [icon]="faTriangleExclamation"></fa-icon>
				<h2 class="m-0"><strong>{{'PENDING_PATIENT_MODAL.EM_TITLE_STEP_1' | translate}}</strong></h2>
			</div>
			<p>{{'PENDING_PATIENT_MODAL.EM_SUBTITLE_1' | translate}}</p>
			<p>{{'PENDING_PATIENT_MODAL.EM_SUBTITLE_2' | translate}}</p>
		</div>
		<div class="row">
			<ng-template [ngTemplateOutlet]="pendingPatientCard"></ng-template>

			<div class="col patient-card">
				<h5 class="mb-3 text-center"><strong>{{'PENDING_PATIENT_MODAL.EXISTING_PAT_TITLE' | translate}}</strong></h5>
				<div class="content">
					<div class="row mb-3">
						<div class="half-container">
							<label for="firstName">{{'PENDING_PATIENT_MODAL.FIRSTNAME' | translate}}</label>
							<br />
							<div class="matching-input-container">
								<div class="icon-check">
									<fa-icon [icon]="faCircleCheck" faCircleCheck aria-hidden="true"></fa-icon>
								</div>
								<input type="text" class="form-control match-found" readonly id="firstName" name="firstName" [ngModel]="exactMatchConflictPatient.firstName" />
							</div>
						</div>
						<div class="half-container">
							<label for="lastName">{{'PENDING_PATIENT_MODAL.LASTNAME' | translate}}</label>
							<br />
							<div class="matching-input-container">
								<div class="icon-check">
									<fa-icon [icon]="faCircleCheck" faCircleCheck aria-hidden="true"></fa-icon>
								</div>
								<input type="text" class="form-control match-found" readonly id="lastName" name="lastName" [ngModel]="exactMatchConflictPatient.lastName" />
							</div>
						</div>
					</div>
					<div class="row mb-3">
						<div class="half-container">
							<label for="birthDate">{{'PENDING_PATIENT_MODAL.BIRTHDATE' | translate}}</label>
							<br />
							<div class="matching-input-container">
								<div class="icon-check">
									<fa-icon [icon]="faCircleCheck" faCircleCheck aria-hidden="true"></fa-icon>
								</div>
								<input type="text" class="form-control match-found" readonly id="birthDate" name="birthDate" [ngModel]="this.formatDate(exactMatchConflictPatient.birthDate)" />
							</div>
						</div>
						<div class="half-container">
							<label for="lastVisit">{{'PENDING_PATIENT_MODAL.LAST_VISIT' | translate}}</label>
							<br />
							<input type="text" class="form-control" readonly id="lastVisit" name="lastVisit" [ngModel]="session.formatDate(exactMatchConflictPatient.last_visit)" />
						</div>
					</div>
					<div class="row mb-3">
						<div class="half-container">
							<label for="pid">{{'PENDING_PATIENT_MODAL.PID' | translate}}</label>
							<br />
							<input type="text" class="form-control" readonly id="pid" name="pid" [ngModel]="exactMatchConflictPatient.personal_id" />
						</div>

						<div class="half-container">
							<label for="gender">{{'PENDING_PATIENT_MODAL.GENDER' | translate}}</label>
							<br />
							<input type="text" class="form-control" readonly id="gender" name="gender" [ngModel]="'SEX.' + exactMatchConflictPatient.sex | translate" />
						</div>
					</div>
					<div class="row mb-3">
						<div class="half-container">
							<label for="country">{{'PENDING_PATIENT_MODAL.COUNTRY' | translate}}</label>
							<br />
							<input type="text" class="form-control" readonly id="country" name="country" [ngModel]="exactMatchConflictPatient.addresses[0].country" />
						</div>
						<div class="half-container">
							<label for="phone">{{'PENDING_PATIENT_MODAL.PHONE' | translate}}</label>
							<br />
							<input type="text" class="form-control" readonly id="phone" name="phone" [ngModel]="exactMatchConflictPatient.addresses[0].phone1" />
						</div>
					</div>
					<div class="mb-4">
						<label for="email">{{'PENDING_PATIENT_MODAL.EMAIL' | translate}}</label>
						<br />
						<input type="text" class="form-control" readonly id="email" name="email" [ngModel]="exactMatchConflictPatient.addresses[0].ref_email" />
					</div>
					<button class="btn btn-primary" (click)="exactMatchHandleSamePatient()">{{'PENDING_PATIENT_MODAL.EXACT_MATCH_SAME_PAT' | translate}}</button>
				</div>
			</div>
		</div>
	</div>

	<div *ngIf="step === 2" class="exact-match-step-2">
		<div class="mb-5">
			<div class="mb-3 text-center">
				<h2 class="m-0"><strong>{{'PENDING_PATIENT_MODAL.EM_TITLE_STEP_2' | translate}}</strong></h2>
			</div>
			<p class="subtitle warning">{{'PENDING_PATIENT_MODAL.EM_SUBTITLE_3' | translate}}</p>
		</div>
		<div class="d-flex flex-column align-items-center">
			<div class="col patient-card mb-4">
				<span class="header-icon-container">
					<fa-icon [icon]="faUserCheck"></fa-icon>
				</span>
				<div class="content">
					<div class="row mb-3">
						<div class="half-container">
							<label for="firstName">{{'PENDING_PATIENT_MODAL.FIRSTNAME' | translate}}</label>
							<br />
							<input type="text" class="form-control" readonly id="firstName" name="firstName" [ngModel]="pendingPatient.firstName" />
						</div>
						<div class="half-container">
							<label for="lastName">{{'PENDING_PATIENT_MODAL.LASTNAME' | translate}}</label>
							<br />
							<input type="text" class="form-control" [maxLength]="pendingPatient.lastName.length + 1" id="lastName" name="lastName" [(ngModel)]="exactMatchNewLastName" />
						</div>
					</div>
					<div class="row mb-3">
						<div class="half-container">
							<label for="birthDate">{{'PENDING_PATIENT_MODAL.BIRTHDATE' | translate}}</label>
							<br />
							<input type="text" class="form-control" readonly id="birthDate" name="birthDate" [ngModel]="this.formatDate(pendingPatient.birthDate)" />
						</div>
						<div class="half-container">
							<label for="gender">{{'PENDING_PATIENT_MODAL.GENDER' | translate}}</label>
							<br />
							<input type="text" class="form-control" readonly id="gender" name="gender" [ngModel]="'SEX.' + pendingPatient.sex | translate" />
						</div>
					</div>
					<div class="mb-3">
						<label for="pid">{{'PENDING_PATIENT_MODAL.PID' | translate}}</label>
						<br />
						<input type="text" class="form-control" readonly id="pid" name="pid" [ngModel]="pendingPatient.personal_id" />
					</div>
					<div class="row mb-3">
						<div class="half-container">
							<label for="country">{{'PENDING_PATIENT_MODAL.COUNTRY' | translate}}</label>
							<br />
							<input type="text" class="form-control" readonly id="country" name="country" [ngModel]="pendingPatient.addresses[0].country" />
						</div>
						<div class="half-container">
							<label for="phone">{{'PENDING_PATIENT_MODAL.PHONE' | translate}}</label>
							<br />
							<input type="text" class="form-control" readonly id="phone" name="phone" [ngModel]="pendingPatient.addresses[0].phone1" />
						</div>
					</div>
					<div class="mb-4">
						<label for="email">{{'PENDING_PATIENT_MODAL.EMAIL' | translate}}</label>
						<br />
						<input type="text" class="form-control" readonly id="email" name="email" [ngModel]="pendingPatient.addresses[0].ref_email" />
					</div>
				</div>
			</div>
			<div class="buttons-row">
				<button class="btn btn-primary" [disabled]="exactMatchNewLastName.toLowerCase() === pendingPatient.lastName.toLowerCase()" (click)="onSaveNewPatient()">{{'PENDING_PATIENT_MODAL.SAVE' | translate}}</button>
				<button class="btn btn-secondary" (click)="handleStep(1)">{{'PENDING_PATIENT_MODAL.UNDO' | translate}}</button>
			</div>
		</div>
	</div>
</div>

<ng-template #potentialDuplicate>
	<div class="col m-4">
		<div *ngIf="step === 1">
			<div>
				<div class="warning-title mb-3">
					<fa-icon [icon]="faTriangleExclamation"></fa-icon>
					<h2 class="m-0"><strong>{{'PENDING_PATIENT_MODAL.PD_TITLE_STEP_1' | translate}}</strong></h2>
				</div>
				<p>{{'PENDING_PATIENT_MODAL.PD_SUBTITLE_1' | translate}}</p>
				<p>{{'PENDING_PATIENT_MODAL.PD_SUBTITLE_2' | translate}}</p>
			</div>
			<div class="row">
				<ng-template [ngTemplateOutlet]="pendingPatientCard"></ng-template>

				<div class="col patient-card">
					<h5 class="mb-3 text-center"><strong>{{'PENDING_PATIENT_MODAL.POTENTIAL_MATCHES_TITLE' | translate}}</strong></h5>
					<div class="content">
						<div class="col match-cards mb-4">
							<div *ngFor="let patient of conflictingCurrentPatients; let i=index">
								<div class="row mb-4 match-patient-card">
									<div class="col flex-grow-0">
										<input class="card-radio-input" type="radio" [id]="i" [value]="i" [(ngModel)]="selectedPatientIndex" />
									</div>
									<div class="col">
										<div class="row mb-3">
											<div class="half-container">
												<label for="firstName">{{'PENDING_PATIENT_MODAL.FIRSTNAME' | translate}}</label>
												<br />
												<div class="matching-input-container">
													<div class="icon-check">
														<fa-icon *ngIf="patient.firstName.toLowerCase() === pendingPatient.firstName.toLowerCase(); else faCircleXmarkIcon" [icon]="faCircleCheck" faCircleCheck aria-hidden="true"></fa-icon>
														<ng-template #faCircleXmarkIcon>
															<fa-icon [icon]="faCircleXmark" faCircleXmark aria-hidden="true"></fa-icon>
														</ng-template>
													</div>
													<input type="text" class="form-control match-found" [ngClass]="patient.firstName.toLowerCase() === pendingPatient.firstName.toLowerCase() ? 'match-found' : 'no-match-found'" readonly id="firstName" name="firstName" [ngModel]="patient.firstName" />
												</div>
											</div>
											<div class="half-container">
												<label for="lastName">{{'PENDING_PATIENT_MODAL.LASTNAME' | translate}}</label>
												<br />
												<div class="matching-input-container">
													<div class="icon-check">
														<fa-icon *ngIf="patient.lastName.toLowerCase() === pendingPatient.lastName.toLowerCase(); else faCircleXmarkIcon" [icon]="faCircleCheck" faCircleCheck aria-hidden="true"></fa-icon>
														<ng-template #faCircleXmarkIcon>
															<fa-icon [icon]="faCircleXmark" faCircleXmark aria-hidden="true"></fa-icon>
														</ng-template>
													</div>
													<input type="text" class="form-control match-found" [ngClass]="patient.lastName.toLowerCase() === pendingPatient.lastName.toLowerCase() ? 'match-found' : 'no-match-found'" readonly id="lastName" name="lastName" [ngModel]="patient.lastName" />
												</div>
											</div>
										</div>
										<div class="row mb-3">
											<div class="half-container">
												<label for="birthDate">{{'PENDING_PATIENT_MODAL.BIRTHDATE' | translate}}</label>
												<br />
												<div class="matching-input-container">
													<div class="icon-check">
														<fa-icon [icon]="faCircleCheck" faCircleCheck aria-hidden="true"></fa-icon>
													</div>
													<input type="text" class="form-control match-found" readonly id="birthDate" name="birthDate" [ngModel]="this.formatDate(patient.birthDate)" />
												</div>
											</div>
											<div class="half-container">
												<label for="lastVisit">{{'PENDING_PATIENT_MODAL.LAST_VISIT' | translate}}</label>
												<br />
												<input type="text" class="form-control" readonly id="lastVisit" name="lastVisit" [ngModel]="session.formatDate(patient.last_visit)" />
											</div>
										</div>
										<div *ngIf="conflictingPatientDetailSwitches[i]">
											<div class="row mb-3">
												<div class="half-container">
													<label for="gender">{{'PENDING_PATIENT_MODAL.GENDER' | translate}}</label>
													<br />
													<input type="text" class="form-control" readonly id="gender" name="gender" [ngModel]="'SEX.' + patient.sex | translate" />
												</div>
												<div class="half-container">
													<label for="pid">{{'PENDING_PATIENT_MODAL.PID' | translate}}</label>
													<br />
													<input type="text" class="form-control" readonly id="pid" name="pid" [ngModel]="patient.personal_id" />
												</div>
											</div>
											<div class="row mb-3">
												<div class="half-container">
													<label for="country">{{'PENDING_PATIENT_MODAL.COUNTRY' | translate}}</label>
													<br />
													<input type="text" class="form-control" readonly id="country" name="country" [ngModel]="patient.addresses[0].country" />
												</div>
												<div class="half-container">
													<label for="phone">{{'PENDING_PATIENT_MODAL.PHONE' | translate}}</label>
													<br />
													<input type="text" class="form-control" readonly id="phone" name="phone" [ngModel]="patient.addresses[0].phone1" />
												</div>
											</div>
											<div class="mb-4">
												<label for="email">{{'PENDING_PATIENT_MODAL.EMAIL' | translate}}</label>
												<br />
												<input type="text" class="form-control" readonly id="email" name="email" [ngModel]="patient.addresses[0].ref_email" />
											</div>
										</div>
										<span class="row open-details">
											<span class="w-auto" (click)="handlePatientDetailSwitch(i)">
												<span class="me-3">{{conflictingPatientDetailSwitches[i] ? ('PENDING_PATIENT_MODAL.LESS_DETAILS' | translate) : ('PENDING_PATIENT_MODAL.MORE_DETAILS' | translate)}}</span>
												<fa-icon *ngIf="conflictingPatientDetailSwitches[i]; else caretDownIcon" [icon]="faCaretUp" faCaretUp></fa-icon>
												<ng-template #caretDownIcon><fa-icon [icon]="faCaretDown" faCaretDown></fa-icon></ng-template>
											</span>
										</span>
									</div>
								</div>
							</div>
						</div>
						<button class="btn btn-primary" [disabled]="selectedPatientIndex < 0" (click)="onUpdatePatient()">{{'PENDING_PATIENT_MODAL.UPDATE_SELECTED' | translate}}</button>
					</div>
				</div>
			</div>
		</div>

		<div *ngIf="step === 2" class="potential-duplicate-step-2">
			<div class="mb-4">
				<h4 class="mb-3"><strong>{{'PENDING_PATIENT_MODAL.PD_TITLE_STEP_2' | translate}}</strong></h4>
				<p>{{'PENDING_PATIENT_MODAL.PD_SUBTITLE_3' | translate}}</p>
				<p>{{'PENDING_PATIENT_MODAL.PD_SUBTITLE_4' | translate}}</p>
			</div>
			<div class="row align-items-center">
				<div class="col patient-card mb-4">
					<span class="header-icon-container">
						<fa-icon [icon]="faPersonWalkingArrowRight"></fa-icon>
					</span>
					<h5 class="mb-3 text-center pat-card-title"><strong>{{'PENDING_PATIENT_MODAL.NEW_PAT_TITLE' | translate}}</strong></h5>
					<div class="content">
						<div class="row mb-3">
							<div class="half-container">
								<label for="firstName">{{'PENDING_PATIENT_MODAL.FIRSTNAME' | translate}}</label>
								<br />
								<input type="text" class="form-control match-found" readonly id="firstName" name="firstName" [ngModel]="pendingPatient.firstName" />
							</div>
							<div class="half-container">
								<label for="lastName">{{'PENDING_PATIENT_MODAL.LASTNAME' | translate}}</label>
								<br />
								<input type="text" class="form-control match-found" readonly id="lastName" name="lastName" [ngModel]="pendingPatient.lastName" />
							</div>
						</div>
						<div class="mb-3">
							<label for="birthDate">{{'PENDING_PATIENT_MODAL.BIRTHDATE' | translate}}</label>
							<br />
							<input type="text" class="form-control match-found" readonly id="birthDate" name="birthDate" [ngModel]="this.formatDate(pendingPatient.birthDate)" />
						</div>
						<div class="row mb-3">
							<div class="half-container">
								<label for="gender">{{'PENDING_PATIENT_MODAL.GENDER' | translate}}</label>
								<br />
								<input type="text" class="form-control" readonly id="gender" name="gender" [ngModel]="'SEX.' + pendingPatient.sex | translate" />
							</div>
							<div class="half-container">
								<label for="pid">{{'PENDING_PATIENT_MODAL.PID' | translate}}</label>
								<br />
								<input type="text" class="form-control" readonly id="pid" name="pid" [ngModel]="pendingPatient.personal_id" />
							</div>
						</div>
						<div class="row mb-3">
							<div class="half-container">
								<label for="country">{{'PENDING_PATIENT_MODAL.COUNTRY' | translate}}</label>
								<br />
								<input type="text" class="form-control" readonly id="country" name="country" [ngModel]="pendingPatient.addresses[0].country" />
							</div>
							<div class="half-container">
								<label for="phone">{{'PENDING_PATIENT_MODAL.PHONE' | translate}}</label>
								<br />
								<input type="text" class="form-control" readonly id="phone" name="phone" [ngModel]="pendingPatient.addresses[0].phone1" />
							</div>
						</div>
						<div class="mb-4">
							<label for="email">{{'PENDING_PATIENT_MODAL.EMAIL' | translate}}</label>
							<br />
							<input type="text" class="form-control" readonly id="email" name="email" [ngModel]="pendingPatient.addresses[0].ref_email" />
						</div>
						<button class="btn btn-primary mb-3" (click)="onSelectPatientData(pendingPatient)">{{'PENDING_PATIENT_MODAL.KEEP_DATA' | translate}}</button>
					</div>
				</div>

				<div class="col patient-card mb-4">
					<span class="header-icon-container">
						<fa-icon [icon]="faDatabase"></fa-icon>
					</span>
					<h5 class="mb-3 text-center pat-card-title"><strong>{{'PENDING_PATIENT_MODAL.EXISTING_PAT_TITLE' | translate}}</strong></h5>
					<div class="content">
						<div class="row mb-3">
							<div class="half-container">
								<label for="firstName">{{'PENDING_PATIENT_MODAL.FIRSTNAME' | translate}}</label>
								<br />
								<input type="text" class="form-control match-found" readonly id="firstName" name="firstName" [ngModel]="selectedExistingPatient.firstName" />
							</div>
							<div class="half-container">
								<label for="lastName">{{'PENDING_PATIENT_MODAL.LASTNAME' | translate}}</label>
								<br />
								<input type="text" class="form-control match-found" readonly id="lastName" name="lastName" [ngModel]="selectedExistingPatient.lastName" />
							</div>
						</div>
						<div class="mb-3">
							<label for="birthDate">{{'PENDING_PATIENT_MODAL.BIRTHDATE' | translate}}</label>
							<br />
							<input type="text" class="form-control match-found" readonly id="birthDate" name="birthDate" [ngModel]="this.formatDate(selectedExistingPatient.birthDate)" />
						</div>
						<div class="row mb-3">
							<div class="half-container">
								<label for="gender">{{'PENDING_PATIENT_MODAL.GENDER' | translate}}</label>
								<br />
								<input type="text" class="form-control" readonly id="gender" name="gender" [ngModel]="'SEX.' + selectedExistingPatient.sex | translate" />
							</div>
							<div class="half-container">
								<label for="pid">{{'PENDING_PATIENT_MODAL.PID' | translate}}</label>
								<br />
								<input type="text" class="form-control" readonly id="pid" name="pid" [ngModel]="selectedExistingPatient.personal_id" />
							</div>
						</div>
						<div class="row mb-3">
							<div class="half-container">
								<label for="country">{{'PENDING_PATIENT_MODAL.COUNTRY' | translate}}</label>
								<br />
								<input type="text" class="form-control" readonly id="country" name="country" [ngModel]="selectedExistingPatient.addresses[0].country" />
							</div>
							<div class="half-container">
								<label for="phone">{{'PENDING_PATIENT_MODAL.PHONE' | translate}}</label>
								<br />
								<input type="text" class="form-control" readonly id="phone" name="phone" [ngModel]="selectedExistingPatient.addresses[0].phone1" />
							</div>
						</div>
						<div class="mb-4">
							<label for="email">{{'PENDING_PATIENT_MODAL.EMAIL' | translate}}</label>
							<br />
							<input type="text" class="form-control" readonly id="email" name="email" [ngModel]="selectedExistingPatient.addresses[0].ref_email" />
						</div>
						<button class="btn btn-primary mb-3" (click)="onSelectPatientData(selectedExistingPatient)">{{'PENDING_PATIENT_MODAL.KEEP_DATA' | translate}}</button>
					</div>
				</div>
			</div>
		</div>

		<div *ngIf="step === 3" class="potential-duplicate-step-3">
			<div class="mb-5">
				<div class="mb-3 text-center">
					<h4 class="m-0"><strong>{{'PENDING_PATIENT_MODAL.PD_TITLE_STEP_3' | translate}}</strong></h4>
				</div>
				<p class="subtitle text-center">{{'PENDING_PATIENT_MODAL.PD_SUBTITLE_5' | translate}}</p>
			</div>
			<div class="d-flex flex-column align-items-center">
				<div class="col patient-card mb-4">
					<span class="header-icon-container">
						<fa-icon [icon]="faUserCheck"></fa-icon>
					</span>
					<div class="content">
						<div class="row mb-3">
							<div class="half-container">
								<label for="firstName">{{'PENDING_PATIENT_MODAL.FIRSTNAME' | translate}}</label>
								<br />
								<input type="text" class="form-control" readonly id="firstName" name="firstName" [ngModel]="selectedPatientData.firstName" />
							</div>
							<div class="half-container">
								<label for="lastName">{{'PENDING_PATIENT_MODAL.LASTNAME' | translate}}</label>
								<br />
								<input type="text" class="form-control" readonly id="lastName" name="lastName" [(ngModel)]="selectedPatientData.lastName" />
							</div>
						</div>
						<div class="row mb-3">
							<div class="half-container">
								<label for="birthDate">{{'PENDING_PATIENT_MODAL.BIRTHDATE' | translate}}</label>
								<br />
								<input type="text" class="form-control" readonly id="birthDate" name="birthDate" [ngModel]="this.formatDate(selectedPatientData.birthDate)" />
							</div>
							<div class="half-container">
								<label for="gender">{{'PENDING_PATIENT_MODAL.GENDER' | translate}}</label>
								<br />
								<input type="text" class="form-control" readonly id="gender" name="gender" [ngModel]="'SEX.' + selectedPatientData.sex | translate" />
							</div>
						</div>
						<div class="mb-3">
							<label for="pid">{{'PENDING_PATIENT_MODAL.PID' | translate}}</label>
							<br />
							<input type="text" class="form-control" readonly id="pid" name="pid" [ngModel]="selectedPatientData.personal_id" />
						</div>
						<div class="row mb-3">
							<div class="half-container">
								<label for="country">{{'PENDING_PATIENT_MODAL.COUNTRY' | translate}}</label>
								<br />
								<input type="text" class="form-control" readonly id="country" name="country" [ngModel]="selectedPatientData.addresses[0].country" />
							</div>
							<div class="half-container">
								<label for="phone">{{'PENDING_PATIENT_MODAL.PHONE' | translate}}</label>
								<br />
								<input type="text" class="form-control" readonly id="phone" name="phone" [ngModel]="selectedPatientData.addresses[0].phone1" />
							</div>
						</div>
						<div class="mb-4">
							<label for="email">{{'PENDING_PATIENT_MODAL.EMAIL' | translate}}</label>
							<br />
							<input type="text" class="form-control" readonly id="email" name="email" [ngModel]="selectedPatientData.addresses[0].ref_email" />
						</div>
					</div>
				</div>
				<div class="buttons-row">
					<button class="btn btn-primary" (click)="onUpdateExistingPatientData()">{{'PENDING_PATIENT_MODAL.SAVE' | translate}}</button>
					<button class="btn btn-secondary" (click)="handleStep(2)">{{'PENDING_PATIENT_MODAL.UNDO' | translate}}</button>
				</div>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #pendingPatientCard>
	<div class="col patient-card">
		<h5 class="mb-3 text-center"><strong>{{'PENDING_PATIENT_MODAL.DETAILS_TITLE' | translate}}</strong></h5>
		<div class="content">
			<div class="row mb-3">
				<div class="half-container">
					<label for="firstName">{{'PENDING_PATIENT_MODAL.FIRSTNAME' | translate}}</label>
					<br />
					<input type="text" class="form-control" readonly id="firstName" name="firstName" [ngModel]="pendingPatient.firstName" />
				</div>
				<div class="half-container">
					<label for="lastName">{{'PENDING_PATIENT_MODAL.LASTNAME' | translate}}</label>
					<br />
					<input type="text" class="form-control" readonly id="lastName" name="lastName" [ngModel]="pendingPatient.lastName" />
				</div>
			</div>
			<div class="mb-3">
				<label for="birthDate">{{'PENDING_PATIENT_MODAL.BIRTHDATE' | translate}}</label>
				<br />
				<input type="text" class="form-control" readonly id="birthDate" name="birthDate" [ngModel]="this.formatDate(pendingPatient.birthDate)" />
			</div>
			<div class="row mb-3">
				<div class="half-container">
					<label for="pid">{{'PENDING_PATIENT_MODAL.PID' | translate}}</label>
					<br />
					<input type="text" class="form-control" readonly id="pid" name="pid" [ngModel]="pendingPatient.personal_id" />
				</div>
				<div class="half-container">
					<label for="gender">{{'PENDING_PATIENT_MODAL.GENDER' | translate}}</label>
					<br />
					<input type="text" class="form-control" readonly id="gender" name="gender" [ngModel]="'SEX.' + pendingPatient.sex | translate" />
				</div>
			</div>
			<div class="row mb-3">
				<div class="half-container">
					<label for="country">{{'PENDING_PATIENT_MODAL.COUNTRY' | translate}}</label>
					<br />
					<input type="text" class="form-control" readonly id="country" name="country" [ngModel]="pendingPatient.addresses[0].country" />
				</div>
				<div class="half-container">
					<label for="phone">{{'PENDING_PATIENT_MODAL.PHONE' | translate}}</label>
					<br />
					<input type="text" class="form-control" readonly id="phone" name="phone" [ngModel]="pendingPatient.addresses[0].phone1" />
				</div>
			</div>
			<div class="mb-4">
				<label for="email">{{'PENDING_PATIENT_MODAL.EMAIL' | translate}}</label>
				<br />
				<input type="text" class="form-control" readonly id="email" name="email" [ngModel]="pendingPatient.addresses[0].ref_email" />
			</div>
			<button class="btn btn-primary" (click)="exactMatchConflictPatient ? handleStep(2) : onSaveNewPatient()">{{exactMatchConflictPatient ? ('PENDING_PATIENT_MODAL.EM_SAVE_NEW' | translate) : ('PENDING_PATIENT_MODAL.PD_SAVE_NEW' | translate)}}</button>
		</div>
	</div>
</ng-template>
