import { HttpInterceptor, HttpRequest, HttpHandler } from '@angular/common/http'
import { CookieUser } from '../models/user.model'
import { Util } from '../models/util.model'

export class AuthInterceptorService implements HttpInterceptor {
	intercept(req: HttpRequest<any>, next: HttpHandler) {
		const coockieUSer: CookieUser = JSON.parse(sessionStorage.getItem('user'))

		if (req.url.indexOf('json') > 0) {
			//quando si fa F5 mette Authorization anche alle get degli assets e non serve
			return next.handle(req)
		}

		if (coockieUSer && !req.headers.has('Authorization')) {
			const modifiedREquest = req.clone({
				headers: req.headers
					.set('Authorization', coockieUSer.token)
					.set('caller', 'feWeb2')
					.set('Pragma', 'no-cache')
					.set('Cache-Control', 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0')
					.set('Expires', '0'),
			})

			Util.debug('calling ' + '[' + modifiedREquest.method + '] - ' + modifiedREquest.url)

			return next.handle(modifiedREquest)
		} else {
			return next.handle(req)
		}
	}
}
