import { Config } from '../../config'
import { SortDirection } from '@angular/material/sort'

// 06.07.2017 per i medici, gestito solo per NS
export class Settings {
	// AI_TYPE
	static DSS = 'dss'
	static MCS = 'mcs'
	static NONE = 'none'

	offline_days: number

	anamnesis_group: number
	impact_anamn_group: number
	max_colleagues: number
	brand: string
	remote_support: string //  {Y,N}
	recovery: string //   Y,N,B
	upload_exams: string //  {Y,N,D}
	syncr_patients: string //  {Y,N}
	updates_enabled: string // {Y,N}
	shared_folder: string // {Y,N}
	dicom_level: string

	p_self_reg: string // {Y,N}

	diagnosis_group: number
	telerefract: string // Y/N

	ai_type: string

	// solo per il fundus
	mosaic: string
	cup_to_disc: string

	// questi li puo' cambiare direttamente l'utente
	pdf_format: string // 14.02.2022
	date_format: string
	lang: string
	ecommerce: string //{Y,N}
	free_trial_available: string //{Y,N,U}
	address_on_report: string //{Y,N}

	p_consent_otp: string //{Y,N,U}

	constructor(RawUsDev?) {
		this.offline_days = 1
		this.anamnesis_group = 1
		this.impact_anamn_group = 1000

		this.max_colleagues = 20

		this.brand = Config.BR_DEFAULT

		this.upload_exams = 'Y'
		this.syncr_patients = 'Y'
		this.updates_enabled = 'Y'
		this.shared_folder = 'Y'

		this.cup_to_disc = 'N'
		this.mosaic = 'N'

		this.remote_support = 'N'
		this.recovery = 'N'
		this.dicom_level = 'N'

		this.p_self_reg = 'N'

		this.diagnosis_group = 0
		this.telerefract = 'N'
		this.ai_type = 'none' // default

		this.pdf_format = Config.PDF_FORMATS[0] // "A4";// 14.02.2022
		this.date_format = Config.DATE_FORMATS[0] // "MM/DD/YYYY";  // default americano
		this.lang = 'en' // 16.02.2022

		this.ecommerce = 'N'
		this.free_trial_available = 'N'

		this.address_on_report = 'Y'

		this.p_consent_otp = 'U'

		// 12.05.2021 dovrebbe funzionare se non ci sono sotto-oggetti complessi
		// corrispondenza con i nomi e var

		if (RawUsDev) {
			// console.log(RawUsDev)
			//var myJsonObj = JSON.parse(RawUsDev);  // KO
			// 08.11.2021   AGGIORNAMENTO ANGULAR
			// var myJsonObj = angular.copy(RawUsDev); // e' gia' parserizzato
			var myJsonObj = { ...RawUsDev }
			if (myJsonObj != null) {
				Object.assign(this, myJsonObj)
			}

			if (!this.brand)
				// 14.10.2021 patch
				this.brand = Config.BR_DEFAULT
		} //else {
		//console.log("(Setting - default values)");
		//}

		//console.log("(Setting - costruttore) "+this.toString());
		//console.log("(Setting - costruttore) mosaic: "+this.mosaic+" maxB:"+this.max_colleagues);
	}

	public isFreeTrialAvailable(): boolean {
		return this.free_trial_available == 'Y'
	}
}

// 25.05.2021 per gli utenti manager
export class AdmSettings {
	models: string // per ora partiamo con uno, TODO elenco
	categories: string // 25.10.2021
	diagonal: string // 25.03.2022
	lintel: string

	constructor(rawObj?) {
		this.models = ''
		this.categories = ''
		this.diagonal = 'N'
		this.lintel = 'N'

		if (rawObj) {
			//console.log(rawObj);  // 14.06.2021 solo per test iniziale
			this.models = rawObj.models
			this.categories = rawObj.categories

			this.diagonal = rawObj.diagonal
			this.lintel = rawObj.lintel
		}

		// capire se funziona o fa danni...
		if (this.categories == '') {
			this.categories = '*'
		}
	}
}

// 10.03.2022 per ora questi 2 campi, arrivano sui settings
// ma possono essere modificati dall'utente nella pg profile
export interface Preferences {
	pdf_format: string
	date_format: string
	lang: string
	address_on_report: string
}

export class TablePrefs {
	currentPage: number
	itemsPerPage: number
	sort: string
	dir: SortDirection
	filter: string
	empty: boolean

	constructor(rawObj?) {
		this.currentPage = 0
		this.itemsPerPage = 10
		this.sort = ''
		this.dir = 'desc'
		this.filter = ''
		this.empty = true

		if (rawObj) {
			var myJsonObj = { ...rawObj }
			if (myJsonObj != null) {
				Object.assign(this, myJsonObj)
			}
		}
	}
}

export class TablePrefsDocList extends TablePrefs {
	testUsers: string
	deleteUsers: string
	type: string
	subtype: string
	country: string
	brand: string
	activationDate: { start: string | null; end: string } | string | null
	activationDateRange: { start: string | null; end: string | null }

	constructor(rawObj?) {
		super(rawObj)
		this.testUsers = ''
		this.deleteUsers = ''
		this.type = ''
		this.subtype = ''
		this.country = ''
		this.brand = ''
		this.activationDate = ''
		this.activationDateRange = null

		if (rawObj) {
			var myJsonObj = { ...rawObj }
			if (myJsonObj != null) {
				Object.assign(this, myJsonObj)
			}
		}
	}
}

export class TablePrefsPatList extends TablePrefs {
	gradingStatus: string
	graders: string
	operators: string
	req_date: Date
	due_date: Date
	rep_date: Date

	constructor(rawObj?) {
		super(rawObj)
		this.gradingStatus = ''
		this.graders = ''
		this.operators = ''
		this.req_date = null
		this.due_date = null
		this.rep_date = null

		if (rawObj) {
			var myJsonObj = { ...rawObj }
			if (myJsonObj != null) {
				Object.assign(this, myJsonObj)
			}
		}
	}
}

export class TablePrefsSpecList extends TablePrefs {
	filterTab: string

	constructor(rawObj?) {
		super(rawObj)
		this.filterTab = ''

		if (rawObj) {
			var myJsonObj = { ...rawObj }
			if (myJsonObj != null) {
				Object.assign(this, myJsonObj)
			}
		}
	}
}

// to do da spostare su un model adeguato
export interface filterObj {
	descr: string
	value: any
}
